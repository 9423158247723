import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { Ipayment } from '../types';

import {
  createWallet,
  generateKey,
  getAddressBook,
  getBankInfoFunding,
  getPartners,
  toggleP2P,
  uploadKyc,
  walletHistory,
} from './thunk';
import { getInfoNew } from '../../overview/store/thunk';
import { switchMultiAccount } from '@/store/appState/thunk';
import { loginUser } from '@/modules/auth/store/thunk';
import { getMerchantInfo } from '../../transactions/store/thunk';
import { getFiatExchangeRate } from '../../payment/store/thunk';

const initialState: Ipayment = {
  is_loading: false,
  wallet_balance: 0,
  wallet_address: '',
  currency: '',
  txn_limit_per_day: '',
  payment_config: null,
  p2p_info: {
    kyc_level: null,
    merchant_id: '',
    p2p: [],
    wallet_limit: { payout_amount_per_day: '' },
  },
  min_payout_amounts: {
    NGN: 0,
    KHS: 0,
    KES: 0,
    GHS: 0,
  },
  pending_balance: {
    date_time: '',
    pending_amount: 0,
    next_all_settlement_hours: 0,
    currency: '',
    settled_amount: 0,
  },
  partner_mapping: {
    list: [],
    secret_key: '',
  },

  exchangeInfo: {
    isloading: false,
    localFiat_to_usdt: 0,
    usdt_to_localFiat: 0,
  },
  is_auto_claim_collection_enabled: false,
  wallet_info: {
    loading: false,
    wallet_type: '',
    transaction_type: '',
    data: [],
    address_book: [],
  },
};

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    resetWalletInfo: (state) => {
      state.wallet_info = initialState.wallet_info;
    },
  },
  extraReducers(builder) {
    builder
      // .addCase(loginUser.fulfilled, (state, { payload }) => {
      //   state.p2p_info = { ...payload.p2p_info };
      // })

      .addCase(toggleP2P.fulfilled, (state, { meta }) => {
        state.p2p_info.p2p = state.p2p_info.p2p.map((itm) =>
          itm.partner_code === meta.arg.partner_code
            ? {
                ...itm,
                is_p2p_turned_on: meta.arg.status === false ? 'OFF' : 'ON',
              }
            : itm,
        );
      })
      .addCase(getMerchantInfo.fulfilled, (state, { payload }) => {
        state.p2p_info.kyc_level = payload.kyc_level;
        state.p2p_info.merchant_id = payload.merchant_id;
        state.p2p_info.p2p = payload.p2p;
        state.currency = payload.currency;
        state.txn_limit_per_day = payload.txn_limit_per_day;
        state.wallet_balance = payload.wallet_balance;
        state.is_auto_claim_collection_enabled =
          payload.is_auto_claim_collection_enabled;
      })

      .addCase(getPartners.fulfilled, (state, { payload }) => {
        state.partner_mapping.list = payload;
      })
      .addCase(generateKey.fulfilled, (state, { payload, meta }) => {
        const temp = state.p2p_info.p2p.map((itm) =>
          itm.partner_id === meta.arg.partner_id
            ? { ...itm, merchant_secret: payload.secret_key }
            : itm,
        );
        state.p2p_info.p2p = [...temp];
      })
      .addCase(uploadKyc.fulfilled, (state) => {
        state.p2p_info.kyc_level = 'PENDING';
      })
      .addCase(getFiatExchangeRate.fulfilled, (state, { payload, meta }) => {
        if (meta.arg.type === 'TO_FIAT') {
          state.exchangeInfo.usdt_to_localFiat = payload.value;
        }
        if (meta.arg.type === 'FROM_FIAT') {
          state.exchangeInfo.localFiat_to_usdt = payload.value;
        }
      })
      .addCase(getInfoNew.fulfilled, (state, { payload }) => {
        state.wallet_address = payload.wallet_address;
      })
      .addCase(walletHistory.fulfilled, (state, { payload, meta }) => {
        state.wallet_info.wallet_type = meta.arg.wallet_type;
        state.wallet_info.transaction_type = meta.arg.transaction_type;
        state.wallet_info.data = payload.data;
      })
      .addCase(getAddressBook.fulfilled, (state, { payload }) => {
        state.wallet_info.address_book = payload.data;
      })
      .addMatcher(
        isAnyOf(walletHistory.pending, getAddressBook.pending),
        (state) => {
          state.wallet_info.loading = true;
        },
      )
      .addMatcher(
        isAnyOf(
          walletHistory.fulfilled,
          walletHistory.rejected,
          getAddressBook.fulfilled,
          getAddressBook.rejected,
        ),
        (state) => {
          state.wallet_info.loading = false;
        },
      )
      .addMatcher(isAnyOf(getFiatExchangeRate.pending), (state) => {
        state.exchangeInfo.isloading = true;
      })
      .addMatcher(
        isAnyOf(getFiatExchangeRate.fulfilled, getFiatExchangeRate.rejected),
        (state) => {
          state.exchangeInfo.isloading = initialState.exchangeInfo.isloading;
        },
      )
      .addMatcher(
        isAnyOf(switchMultiAccount.fulfilled, loginUser.fulfilled),
        (state, { payload }) => {
          state.min_payout_amounts = { ...payload.p2p_info.min_payout_amounts };
        },
      )
      .addMatcher(
        isAnyOf(
          switchMultiAccount.fulfilled,
          loginUser.fulfilled,
          getInfoNew.fulfilled,
        ),
        (state, { payload }) => {
          state.p2p_info = { ...payload.p2p_info };
          state.pending_balance = { ...payload.pending_balance };
        },
      )
      .addMatcher(
        isAnyOf(createWallet.fulfilled, getBankInfoFunding.fulfilled),
        (state, { payload }) => {
          state.payment_config = payload;
        },
      )
      .addMatcher(
        isAnyOf(
          createWallet.pending,
          getBankInfoFunding.pending,
          uploadKyc.pending,
          getMerchantInfo.pending,
        ),
        (state) => {
          state.is_loading = true;
        },
      )
      .addMatcher(
        isAnyOf(
          createWallet.fulfilled,
          createWallet.rejected,
          getBankInfoFunding.fulfilled,
          getBankInfoFunding.rejected,
          uploadKyc.fulfilled,
          uploadKyc.rejected,
          getMerchantInfo.fulfilled,
          getMerchantInfo.rejected,
        ),
        (state) => {
          state.is_loading = false;
        },
      );
  },
});

export const walletActions = walletSlice.actions;

export default walletSlice.reducer;
