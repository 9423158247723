import React from 'react';

import * as SelectPrimitive from '@radix-ui/react-select';
import { FiChevronDown, FiChevronUp, FiCheck } from 'react-icons/fi';
import { Label } from '@radix-ui/react-label';
import { mergeClassName } from '../../../utils/utilFunction';
import { CgSpinner } from 'react-icons/cg';

interface IselectItem extends Omit<SelectPrimitive.SelectItemProps, 'value'> {
  value?: any;
}

interface Iselect extends SelectPrimitive.SelectProps {
  name?: string;
  onChange?: SelectPrimitive.SelectProps['onValueChange'];
  onFocus?: (e: React.FocusEvent<HTMLButtonElement, Element>) => void;
  id?: string;
  className?: string;
  contentClassName?: string;
  classNames?: Record<string, string>;
  iconclassName?: string;
  placeholder?: string;
  value?: any;
  label?: string;
  labelClassName?: string;
  required?: any;
  loading?: boolean;
}
export const Select = React.forwardRef(
  (
    {
      children,
      label,
      labelClassName,
      classNames,
      loading,
      disabled,
      onFocus,
      onChange,
      ...props
    }: Iselect,
    forwardedRef: React.Ref<HTMLButtonElement>,
  ) => {
    return (
      <div>
        {label ? (
          <Label
            className={mergeClassName(
              'text-xs font-semibold',
              labelClassName,
              classNames?.label,
            )}>
            {label}
          </Label>
        ) : null}
        <SelectPrimitive.Root
          required={props.required}
          onValueChange={onChange}
          value={props.value}>
          <SelectPrimitive.Trigger
            {...props}
            id={props.id}
            className={mergeClassName(
              'relative flex w-full items-center justify-between rounded-md border border-solid bg-[#F8FAFC] h-12 py-2 pl-3 pr-[1.875rem] border-[#CBD5E1] border-opacity-40',
              props.className,
              classNames?.trigger,
            )}
            ref={forwardedRef}
            disabled={disabled || loading}
            onFocus={onFocus}>
            <SelectPrimitive.Value
              placeholder={props.value || props.placeholder || ''}>
              {props.value || props.placeholder || ''}
            </SelectPrimitive.Value>

            <SelectPrimitive.Icon
              className={mergeClassName(
                'absolute top-1/2 right-3 -translate-y-1/2',
                props.iconclassName,
                classNames?.triggerIcon,
              )}>
              {loading ? (
                <CgSpinner className='animate-spin' />
              ) : (
                <FiChevronDown />
              )}
            </SelectPrimitive.Icon>
          </SelectPrimitive.Trigger>

          <SelectPrimitive.Portal>
            <SelectPrimitive.Content
              className={mergeClassName(
                ' p-0 rounded-md border z-20 border-gray-400 bg-white shadow-[0px_20px_24px_-4px_#1018280A]',
                props.contentClassName,
                classNames?.content,
              )}>
              <SelectPrimitive.ScrollUpButton className='select-scroll-btn flex items-center justify-center'>
                <FiChevronUp />
              </SelectPrimitive.ScrollUpButton>

              <SelectPrimitive.Viewport className=''>
                {children}
              </SelectPrimitive.Viewport>

              <SelectPrimitive.ScrollDownButton className='select-scroll-btn flex items-center justify-center'>
                <FiChevronDown />
              </SelectPrimitive.ScrollDownButton>
            </SelectPrimitive.Content>
          </SelectPrimitive.Portal>
        </SelectPrimitive.Root>
      </div>
    );
  },
);
export const SelectItem = React.forwardRef<HTMLDivElement, IselectItem>(
  ({ children, className, value, ...props }, forwardedRef) => {
    return (
      <SelectPrimitive.Item
        {...props}
        // disabled={props.disabled}
        // hidden={props.hidden}
        className={mergeClassName(
          'flex cursor-pointer items-center justify-between px-4 py-2.5 outline-none first:pt-4 last:pb-4 hover:bg-primary hover:text-white',
          className,
        )}
        value={value}
        ref={forwardedRef}>
        <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>

        <SelectPrimitive.ItemIndicator>
          <FiCheck />
        </SelectPrimitive.ItemIndicator>
      </SelectPrimitive.Item>
    );
  },
);
